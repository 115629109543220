<script>
/* eslint-disable vue/no-unused-components */

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Stat from "./widget";
import RevenueAnalytics from "./revenue";
import SalesAnalytics from "./sales-analytics";
import EarningReport from "./earning";
import Sources from "./sources";
import RecentActivity from "./recent-activity";
import RevenueLocation from "./revenue-location";
import Chat from "./chat";
import Transaction from "./transaction";
import { BASE_URL, REQUEST_TYPES } from "../../common";
import axios from "axios";
import alertMixin from "../../../mixins/alertMixin";

/**
 * Dashboard component
 */
export default {
    mixins: [alertMixin],
    components: {
        Layout,
        PageHeader,
        Stat,
        RevenueAnalytics,
        SalesAnalytics,
        EarningReport,
        Sources,
        RecentActivity,
        RevenueLocation,
        Chat,
        Transaction,
    },
    data() {
        return {
            isBusy: true,
            choosenStaff: null,
            userRole: null,
            requestType: REQUEST_TYPES,
            title: "Dashboard",
            email: "",

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            nocOptions: [
                { value: null, text: "Please select an option", code: "" },
                { value: "CL", text: "Visitor", code: "CL" },
                { value: "RM", text: "Mortgage", code: "RM" },
                { value: "AP", text: "Amendment", code: "AP" },
                { value: "MR", text: "Maintenance ", code: "MR" },
                { value: "IN", text: "Immigration", code: "IN" },
                { value: "TD", text: "Title Deed", code: "TD" },
                { value: "NI", text: "Interim Registration", code: "NI" },
                { value: "CM", text: "Commercial Modifications", code: "CM" },
                { value: "LS", text: "Landscaping", code: "LS" },
                { value: "HI", text: "Home Improvements", code: "HI" },
                { value: "SDR", text: "Security Deposit Refund", code: "SDR" },
                { value: "NR", text: "NOC Revalueation", code: "NR" },
                { value: "BD", text: "Built Drawings", code: "BD" },
                { value: "BRC", text: "Barrier Remote Control", code: "BRC" },
                { value: "TB", text: "Trash Bin", code: "TB" },
            ],
            pageOptions: [10, 25, 50, 100],
            filter: null,

            filterOn: [],
            sortBy: "age",
            sortDesc: false,
        };
    },
    computed: {
        fields() {
            let result = [];

            if (
                this.userRole != "Owner" &&
                this.userRole != "Tenant" &&
                this.userRole != "Accounts" &&
                this.userRole != "Management" &&
                this.userRole != "Audit" &&
                this.userRole != "FM Executive" &&
                this.userRole != "Engineering Executive" &&
                this.userRole != "Maintenance" &&
                this.userRole != "dld"
            ) {
                result = [
                    { key: "id", sortable: true },
                    {
                        key: "ref_no",
                        sortable: true,
                        label: "Reference Number",
                    },
                    {
                        key: "requesttype",
                        sortable: true,
                        label: "Request Name",
                    },
                    { key: "assignto", label: "Assign To" },
                    // "assignto",
                    { key: "created_at", sortable: true },
                    { key: "status", label: "Status" },
                    "Detail",
                ];
            } else {
                result = [
                    { key: "id", sortable: true },
                    {
                        key: "ref_no",
                        sortable: true,
                        label: "Reference Number",
                    },
                    {
                        key: "requesttype",
                        sortable: true,
                        label: "Request Name",
                    },
                    { key: "created_at", sortable: true },
                    { key: "status", label: "Status" },
                    "Detail",
                ];
            }
            return result;
        },
        requests() {
            return this.$store.getters["apidata/getRequets"];
        },
        requestStaffs() {
            return this.$store.getters["apidata/getRequestStaff"];
        },
        pendingPaymentList() {
            return this.$store.getters["apidata/getPendingPaymentList"];
        },
        closedRequestCount() {
            return this.requests.filter((item) => {
                return item.status == 3;
            }).length;
        },
        pendingRequest() {
            return this.requests.filter((item) => {
                return item.status == 0;
            }).length;
        },
        requestLevel() {
            return this.$store.getters["apidata/getLevel"];
        },
        rows() {
            return this.requests.length;
        },
        mappedStaffs() {
            return this.requestStaffs.map((ele) => {
                return {
                    value: ele.uid,
                    text: ele.first_name + " " + ele.last_name,
                };
            });
        },
    },
    mounted() {
        if (localStorage.cs_user_token) {
            this.userRole = localStorage.cs_user_role;
            fetch(`${BASE_URL}dashboard?token=${localStorage.cs_user_token}`)
                .then((res) => {
                    return res.json();
                })
                .then((res) => {
                    this.email = res.user_info.email;
                });

            this.$store
                .dispatch("apidata/fetchRequests", localStorage.cs_user_token)
                .then(() => {
                    this.isBusy = false;
                });

            this.$store.dispatch(
                "apidata/fetchPendingPayments",
                localStorage.cs_user_token
            );
        }
    },
    methods: {
        assignRequest($event, data) {
            console.log(data);
            let payload = {
                assignedTo: $event,
                request_type: data.item.requesttype,
                ref_no: data.item.ref_no,
                level: this.requestLevel,
            };
            let loader = this.$loading.show({
                container: this.fullPage ? null : this.$refs.formContainer,
                canCancel: false,
                onCancel: this.onCancel,
            });
            return axios
                .post(
                    `${BASE_URL}request-assign?token=${localStorage.cs_user_token}`,
                    payload
                )
                .then((res) => {
                    loader.hide();
                    console.log(res);
                    this.$store.dispatch(
                        "apidata/fetchRequests",
                        localStorage.cs_user_token
                    );
                    this.successPopUp("Request assinged");
                })
                .catch((err) => {
                    console.log(err);
                    loader.hide();
                    this.$router.push("/");
                    // this.errorMsg("Something went wrong");
                });
        },

        test() {
            this.$router.push({
                path: `/new/payment/${2}/${3}`,
            });
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        viewItem(data) {
            console.log(data.item);
            //visitor log request
            if (data.item.requesttype === 1) {
                this.$router.push({
                    path: `/visitor-log/${data.item.id}`,
                });
            }
            //mortgage request
            if (data.item.requesttype === 2) {
                this.$router.push({
                    path: `/mortgage/${data.item.id}`,
                });
            }
            //amendment request
            if (data.item.requesttype === 3) {
                this.$router.push({
                    path: `/amendment/${data.item.id}`,
                });
            }
            //maintenance request
            if (data.item.requesttype === 4) {
                this.$router.push({
                    path: `/maintenance/${data.item.id}`,
                });
            }
            //immigratioon request
            if (data.item.requesttype === 5) {
                console.log("immigration");
                this.$router.push({
                    path: `/immigration/${data.item.id}`,
                });
            }
            //title deed request
            if (data.item.requesttype === 6) {
                console.log("titledeed");
                this.$router.push({
                    path: `/titledeed/${data.item.id}`,
                });
            }
            //interim registration request
            if (data.item.requesttype === 7) {
                console.log("interim registration");
                this.$router.push({
                    path: `/interim/${data.item.id}`,
                });
            }
            //home improvement request
            if (data.item.requesttype === 12) {
                console.log("home improvement");
                this.$router.push({
                    path: `/home-improvement/${data.item.id}`,
                });
            }
            //commercial modifiaction
            if (data.item.requestType === 17) {
                this.$router.push({
                    path: `/modification/${data.item.id}`,
                });
            }
            //landscape request
            if (data.item.requesttype === 20) {
                console.log("landscaping");
                this.$router.push({
                    path: `/land-scaping/${data.item.id}`,
                });
            }
            //security deposit refund
            if (data.item.requesttype === 21) {
                console.log("security refund");
                this.$router.push({
                    path: `/security-refund/${data.item.id}`,
                });
            }
            //noc revalidation
            if (data.item.requesttype === 22) {
                console.log("noc revalidation");
                this.$router.push({
                    path: `/noc-revalidation/${data.item.id}`,
                });
            }
            //buit in drawings
            if (data.item.requesttype === 23) {
                console.log("noc revalidation");
                this.$router.push({
                    path: `/built-drawings/${data.item.id}`,
                });
            }

            //barrier remote control
            if (data.item.requesttype === 24) {
                console.log("barrier remote control");
                this.$router.push({
                    path: `/barrier-control/${data.item.id}`,
                });
            }
            //trashbin requests
            if (data.item.requesttype === 25) {
                console.log("trash bin");
                this.$router.push({
                    path: `/trash-bin/${data.item.id}`,
                });
            }
        },
    },
};
</script>

<template>
    <div class="page-contents">
        <Layout>
            <div class="row">
                <div class="col-lg-3">
                    <b-card
                        header-class="bg-transparent border-primary"
                        class="border border-primary card_first"
                    >
                        <template v-slot:header>
                            <h5 class="my-0 text-primary">Payment Request</h5>
                        </template>
                        <h2 class="card-title mt-0">
                            <img
                                class="dashboard_card--icon"
                                src="../../../assets/images/dashboard/Group 90@2x.png"
                                alt=""
                            />
                            <span class="dashboard_card--text">
                                {{ pendingPaymentList.length }}</span
                            >
                        </h2>
                    </b-card>
                </div>
                <div class="col-lg-3">
                    <b-card
                        header-class="bg-transparent border-primary"
                        class="border border-primary"
                    >
                        <template v-slot:header>
                            <h5 class="my-0 text-primary">Total Request</h5>
                        </template>
                        <h2 class="card-title mt-0">
                            <img
                                class="dashboard_card--icon"
                                src="../../../assets/images/dashboard/Icon awesome-file-alt@2x.png"
                                alt=""
                            />
                            <span class="dashboard_card--text">
                                {{ requests.length }}</span
                            >
                        </h2>
                    </b-card>
                </div>
                <div class="col-lg-3">
                    <b-card
                        header-class="bg-transparent border-primary"
                        class="border border-primary"
                    >
                        <template v-slot:header>
                            <h5 class="my-0 text-primary">Pending Request</h5>
                        </template>
                        <h2 class="card-title mt-0">
                            <img
                                class="dashboard_card--icon"
                                src="../../../assets/images/dashboard/Icon awesome-clock@2x.png"
                                alt=""
                            />
                            <span class="dashboard_card--text">
                                {{ pendingRequest }}</span
                            >
                        </h2>
                    </b-card>
                </div>
                <div class="col-lg-3">
                    <b-card
                        header-class="bg-transparent border-primary"
                        class="border border-primary card_last"
                    >
                        <template v-slot:header>
                            <h5 class="my-0 text-primary">Closed Request</h5>
                        </template>
                        <h2 class="card-title mt-0">
                            <img
                                class="dashboard_card--icon"
                                src="../../../assets/images/dashboard/Icon awesome-thumbs-up@2x.png"
                                alt=""
                            />
                            <span class="dashboard_card--text">
                                {{ closedRequestCount }}</span
                            >
                        </h2>
                    </b-card>
                </div>
            </div>
            <!-- <b-overlay :show="isBusy" rounded="sm"> -->
            <div class="row">
                <div class="col-12">
                    <div class="card request_card">
                        <div class="card-body">
                            <h4 class="card-title">Latest Requests</h4>
                            <div class="row mt-4">
                                <div class="col-sm-12 col-md-4">
                                    <div
                                        id="tickets-table_length"
                                        class="dataTables_length"
                                    >
                                        <label
                                            class="d-inline-flex align-items-center"
                                        >
                                            Show&nbsp;
                                            <b-form-select
                                                v-model="perPage"
                                                size="sm"
                                                :options="pageOptions"
                                            ></b-form-select
                                            >&nbsp;entries
                                        </label>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-4">
                                    <div
                                        id="tickets-table_length"
                                        class="dataTables_length"
                                    >
                                        <label
                                            class="d-inline-flex align-items-center"
                                        >
                                            Filter&nbsp;
                                            <b-form-select
                                                v-model="filter"
                                                size="sm"
                                                :options="nocOptions"
                                            ></b-form-select
                                            >&nbsp;Noc's
                                        </label>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-4">
                                    <div
                                        id="tickets-table_filter"
                                        class="dataTables_filter text-md-right"
                                    >
                                        <label
                                            class="d-inline-flex align-items-center"
                                        >
                                            Search:
                                            <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search By Ref..."
                                                class="form-control form-control-sm ml-2"
                                            ></b-form-input>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="table-responsive mb-0">
                                <b-table
                                    :items="requests"
                                    :busy="isBusy"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    @filtered="onFiltered"
                                    sort-icon-left
                                >
                                    <template #table-busy>
                                        <div
                                            class="text-center text-primary my-2"
                                        >
                                            <b-spinner
                                                class="align-middle"
                                            ></b-spinner>
                                            <strong>Loading</strong>
                                        </div>
                                    </template>
                                    <template v-if="requests.length === 0">
                                        <div
                                            class="text-center text-primary my-2"
                                        >
                                            <strong>No requestes found</strong>
                                        </div>
                                    </template>
                                    <template v-slot:cell(detail)="data">
                                        <b-button
                                            variant="primary"
                                            @click="viewItem(data)"
                                            >View</b-button
                                        >
                                    </template>

                                    <template #cell(requesttype)="data">
                                        <div
                                            v-for="request in requestType"
                                            :key="request.id"
                                        >
                                            <p
                                                v-if="
                                                    data.item.requesttype ===
                                                    request.id
                                                "
                                            >
                                                {{ request.type }}
                                            </p>
                                        </div>
                                    </template>

                                    <template #cell(status)="data">
                                        <p
                                            v-if="data.item.status === 0"
                                            id="pending"
                                        >
                                            Pending
                                        </p>
                                        <p
                                            v-if="data.item.status === 1"
                                            id="started"
                                        >
                                            Started
                                        </p>
                                        <p
                                            v-if="data.item.status === 2"
                                            id="completed"
                                        >
                                            Completed
                                        </p>
                                        <p
                                            v-if="data.item.status === 3"
                                            id="closed"
                                        >
                                            Closed
                                        </p>
                                        <p
                                            v-if="data.item.status === 4"
                                            id="awaiting"
                                        >
                                            Awaiting Completion Approval
                                        </p>
                                    </template>
                                    <template #cell(assignto)="data">
                                        <b-form-select
                                            v-if="requestLevel == 1"
                                            @change="
                                                assignRequest($event, data)
                                            "
                                            v-model="data.item.level_1_uid"
                                            :options="mappedStaffs"
                                        >
                                        </b-form-select>

                                        <b-form-select
                                            @change="
                                                assignRequest($event, data)
                                            "
                                            v-if="
                                                requestLevel == 2 &&
                                                userRole == 'Front Desk' &&
                                                data.item.other_dept_assigner !=
                                                    0
                                            "
                                            v-model="
                                                data.item.other_dept_assigner
                                            "
                                            :options="mappedStaffs"
                                        >
                                        </b-form-select>

                                        <b-form-select
                                            @change="
                                                assignRequest($event, data)
                                            "
                                            v-if="
                                                requestLevel == 2 &&
                                                userRole == 'Front Desk' &&
                                                data.item.other_dept_assigner ==
                                                    0
                                            "
                                            v-model="data.item.level_2_uid"
                                            :options="mappedStaffs"
                                        >
                                        </b-form-select>

                                        <b-form-select
                                            @change="
                                                assignRequest($event, data)
                                            "
                                            v-if="
                                                requestLevel == 2 &&
                                                userRole == 'FM Assigner'
                                            "
                                            v-model="data.item.level_2_uid"
                                            :options="mappedStaffs"
                                        >
                                        </b-form-select>

                                        <b-form-select
                                            @change="
                                                assignRequest($event, data)
                                            "
                                            v-if="requestLevel == 3"
                                            v-model="data.item.level_3_uid"
                                            :options="mappedStaffs"
                                        >
                                        </b-form-select>
                                    </template>
                                </b-table>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div
                                        class="dataTables_paginate paging_simple_numbers float-right"
                                    >
                                        <ul
                                            class="pagination pagination-rounded mb-0"
                                        >
                                            <b-pagination
                                                v-model="currentPage"
                                                :total-rows="rows"
                                                :per-page="perPage"
                                            ></b-pagination>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- </b-overlay> -->
        </Layout>
    </div>
</template>
<style scoped>
.page-contents {
    background-image: url("../../../assets/images/dashboard/background-dash@2x.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.request_card {
    opacity: 0.9;
}
.dashboard_card--icon {
    height: 50px;
    width: 40px;
    object-fit: contain;
}
.dashboard_card--text {
    padding: 10px;
    margin: 10px;
    font-size: 1.5rem;
}
#pending {
    color: #ff635c;
}
#started {
    color: #ffc71a;
}
#completed {
    color: #19b699;
}
#closed {
    color: #777;
}
#awaiting {
    color: #1b3863;
}
.card_first {
    -webkit-transform: perspective(600) rotateY(18deg);
    -moz-transform: perspective(600) rotateY(18deg);
    -ms-transform: perspective(600) rotateY(18deg);
    -o-transform: perspective(600) rotateY(18deg);
}
.card_last {
    -webkit-transform: perspective(600) rotateY(-18deg);
    -moz-transform: perspective(600) rotateY(-18deg);
    -ms-transform: perspective(600) rotateY(-18deg);
    -o-transform: perspective(600) rotateY(-18deg);
}
</style>
